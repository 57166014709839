<template>
  <div>
    <el-row>
      <!--篩選區塊-->
      <!--<el-divider content-position="right"> 過濾條件 </el-divider>-->
      <div style="text-align: right;">
        <el-select v-model="listQuery.serviceId" size="small" v-bind:placeholder="$t('AllOperator')"
          class="filter-item-mid" v-if="serviceList.length > 0">
          <el-option key="0" :label="$t('AllOperator')" value="0"></el-option>
          <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'" :value="item.id" />
        </el-select>
        <el-select v-model="listQuery.stationId" size="small" :placeholder="$t('AllChargingStation')" class="filter-item-mid">
          <el-option key="0" :label="$t('AllChargingStation')" value="0"></el-option>
          <el-option v-for="item in stationList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
        <!--
        <el-date-picker v-model="dateRange" size="small" class="filter-item" type="daterange" value-format="yyyy-MM-dd"
          range-separator="-" :start-placeholder="$t('Start')+$t('Date')" :end-placeholder="$t('End')+$t('Date')">
        </el-date-picker>
        -->
        <el-date-picker
            v-model="dateStart"
            size="small"
            class="filter-item"            
            type="date"
            value-format="yyyy-MM-dd"
            >
        </el-date-picker>             
        <el-date-picker
            v-model="dateEnd"
            size="small"
            class="filter-item"            
            type="date"
            value-format="yyyy-MM-dd"
            >
        </el-date-picker>
        <el-input size="small" v-model="listQuery.searchTerm" placeholder="查詢卡號或識別碼" class="filter-item" clearable style="width: 180px;"/>
      </div>
    </el-row>
    <el-divider content-position="right" >
      <span v-loading="statisticsLoading" style="font-weight: bold; font-size: 1.2em;">充電總度數: {{statisticsData.sumMeter == undefined? '':(statisticsData.sumMeter/1000)}} 度</span>
    </el-divider>
    <el-row style="text-align: left; font-size: .8em; padding-right: 10px; color: dimgrey; top: -10px; padding-left: 10px;" >
      <span >產製時間: {{ reportDate }} </span>
    </el-row>
    <el-table v-loading="listLoading" :data="list" style="width: 100%;" :cell-style="{ padding: '0', height: '40px' }" @sort-change="tableSortChange"><!-- @row-click="showDetail"-->
      <el-table-column align="center" type="index" label="#" >
        <template slot-scope="scope">
          <span>{{(listQuery.page) * listQuery.size + scope.$index + 1}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="account_value" :label="$t('CardNumber')" sortable="custom">
        <template slot-scope="scope">
          <span :title="scope.row.accountValue"> {{ scope.row.accountValueName }} </span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="id" :label="$t('ChargingSessionId')" />
      <el-table-column align="center" prop="station_id" :label="$t('ChargingStation')" sortable="custom">
        <template slot-scope="scope">
          {{ fetchStationName(scope.row.stationId) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="充電樁#槍">
        <template slot-scope="scope">
          {{ scope.row.chargePointName + "#" + scope.row.connectorId }}
        </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('ChargingTime')">
        <template slot-scope="scope">
          {{ diffTime(scope.row.stopTime, scope.row.startTime) }}
        </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('ChargingMeter')">
        <template slot-scope="scope">
          {{ diffMetter(scope.row.meterStop, scope.row.meterStart) }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="stationId" :label="$t('ChargingStatus')">
        <template slot-scope="scope">
          {{ formatChargingStatus(scope.row.status) }}
        </template>
      </el-table-column>
      <!--<el-table-column align="center" prop="billId" label="交易編號">
        <template slot-scope="scope">
          <el-link type="primary" @click.stop="showPayment(scope.row)">{{ scope.row.billId }}</el-link>
        </template>
      </el-table-column>-->
      <!--
      <el-table-column align="center" prop="billType" label="交易方式" />
      <el-table-column align="center" prop="billAmout" label="交易金額" />
      <el-table-column align="center" prop="billStatus" label="交易狀態" />-->
      <el-table-column align="center" prop="create_time" :label="$t('Create')+$t('Date')" sortable="custom">
        <template slot-scope="scope">
          {{ scope.row.createTime }}
        </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('Operate')" width="70px">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="showDetail(scope.row)"><svg-icon
              iconName="view-details" /></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分頁組件-->
    <pagination v-show="total > 0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
    <RecordDetail :dialogVisible="dialogDetailVisible" :activeTab="activeTab" :recordData="recordData" @close="dialogDetailOnClose()" />

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { mixins } from '@/views/common/mixins.js'
import RecordDetail from './RecordDetail.vue'
import {refreshToken,stopRefreshToken} from "@/utils/auth";
import { apiUrl } from "@/utils/main";
export default {
  name: 'Rfid',
  components: {
    Pagination,
    RecordDetail
  },
  mixins: [mixins],
  data() {
    return {
      dialogWidth: 0,
      total: 0,
      list: [],
      listLoading: false,
      listQuery: {
        type: "RFID",
        serviceId: "0",
        stationId: "0",
        startdate: undefined,
        enddate: undefined,
        searchTerm: undefined,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 15
      },
      serviceList: [],
      stationList: [],
      dateRange: undefined,
      dateStart: undefined,
      dateEnd:   undefined,
      recordData: undefined,
      activeTab: "charging",
      //chargingData: undefined,
      paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
      dialogDetailVisible: false,
      groupData: undefined, // 群組資訊
      dialogPreviewVisible: false,
      previewGroupId: undefined, // 預覽群組ID
      profileGroupType: 'general',
      statisticsLoading: false,
      statisticsData: {},
      reportDate: this.formatCurrentDateTime()
    };
  },
  watch: {
    paginationSelectedPage: {
      handler: function (newValue) {
        this.listQuery.page = newValue - 1
      }
    },
    'listQuery.enabled': function () {
      this.listQuery.page = 0
      this.resetPage(this.getList)
      this.getStatistics()
    },
    'listQuery.serviceId': function () {
      this.listQuery.page = 0
      this.stationList = []
      this.listQuery.stationId = "0"
      this.getStationOptions()
      this.resetPage(this.getList)
      this.getStatistics()
    },
    'listQuery.stationId': function () {
      this.listQuery.page = 0
      this.resetPage(this.getList)
      this.getStatistics()
    },
    'listQuery.type': function () {
      this.listQuery.page = 0
      this.resetPage(this.getList)
      this.getStatistics()
    },
    'listQuery.searchTerm': function () {
      if(this.listQuery.searchTerm != undefined && this.listQuery.searchTerm.length > 0) {
        this.listQuery.startdate = undefined
        this.listQuery.enddate = undefined
        this.dateStart = undefined
        this.dateEnd = undefined
      }
      this.listQuery.page = 0
      this.resetPage(this.getList)
      this.getStatistics()
    },
    /*
    dateRange: function(val) {
      if (val) {
        this.listQuery.startdate = this.dateRange[0]
        this.listQuery.enddate = this.dateRange[1]
      } else {
        this.listQuery.startdate = undefined
        this.listQuery.enddate = undefined
      }
      this.resetPage(this.getList)
      this.getStatistics()
    },
    */
    dateStart: function(val) {
      if (val) {
        this.listQuery.startdate = this.dateStart
      } else {
        this.listQuery.startdate = undefined
      }
        this.listAll= [];
        this.listQuery.page = 0
        this.resetPage(this.getList)
        this.getStatistics()
    },   
    dateEnd: function(val) {
      if (val) {
        this.listQuery.enddate = this.dateEnd
      } else {
        this.listQuery.enddate = undefined
      }
        this.listAll= [];
        this.listQuery.page = 0
        this.resetPage(this.getList)
        this.getStatistics()
    },
  },
  created() {

    console.log(this.$route.params.start)
    if(this.$route.params.start != undefined) {
      this.listQuery.startdate = this.$route.params.start
      this.dateStart = this.$route.params.start
    }
    console.log(this.$route.params.start)
    if(this.$route.params.end != undefined) {
      this.listQuery.enddate = this.$route.params.end
      this.dateEnd = this.$route.params.end
    }
    //console.log(this.$route.query)
    if(this.$route.query.start != undefined) {
      //console.log(this.$route.query.start)
      this.listQuery.startdate = this.$route.query.start
      this.dateStart = this.$route.query.start
    }
    if(this.$route.query.end != undefined) {
      //console.log(this.$route.query.end)
      this.listQuery.enddate = this.$route.query.end
      this.dateEnd = this.$route.query.end
    }

    // 如果都沒有預設的內容，則顯示最近 24 小時的資訊
    if(this.listQuery.startdate==undefined && this.listQuery.enddate==undefined) {
      let date = new Date()
      let daysAgo = new Date()
      daysAgo = daysAgo.setDate(daysAgo.getDate()-7)
      this.listQuery.startdate = this.parseDateTime(daysAgo)
      this.dateStart = this.parseDateTime(daysAgo)
      this.listQuery.enddate = this.parseDateTime(date)
      this.dateEnd = this.parseDateTime(date)
    }

    //this.dateRange = [this.listQuery.startdate==undefined?"":this.listQuery.startdate, this.listQuery.enddate==undefined?"":this.listQuery.enddate]
    this.listQuery.startdate==undefined?"":this.listQuery.startdate
    this.listQuery.enddate==undefined?"":this.listQuery.enddate
    
    this.dialogWidth = this.setDialogWidth()
    // await this.$store.dispatch("getUnitTable")

    this.getServiceOptions();
    this.getStationOptions();
    this.getStatistics()

    //上面設定日期後觸發 getList 動作
    this.resetPage(this.getList)
    //refreshToken();
  },
  beforeDestroy(){
    console.log("call beforeDestroy");
    //stopRefreshToken();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },
  methods: {
    getList() {
      this.listLoading = true
      this.axios.get('/api/v1/charging', { params: this.listQuery }).then(res => {
        this.list = res.data.content
        // console.log(this.list)
        this.total = res.data.totalElements
        this.listLoading = false

        this.reportDate = this.formatCurrentDateTime()
      })
    },
    getServiceOptions() {
      this.listLoading = true
      this.axios.get('/api/v1/common/service/enabled').then(res => {
        this.serviceList = res.data
      })
    },
    getStationOptions() {
      this.listLoading = true
      this.axios.get('/api/v1/common/station/enabled', { params: this.listQuery }).then(res => {
        this.stationList = res.data
      })
    },
    async getStatistics() {
      this.statisticsLoading = true
      this.axios.get('/api/v1/charging/statistics', { params: this.listQuery }).then(res => {
        this.statisticsData = res.data
        this.statisticsLoading = false
      })
    },
    showDetail(row) {
      console.log("showDetail")
      this.dialogDetailVisible = true
      this.activeTab = "charging"
      this.recordData = row
    },
    showPayment(row) {
      console.log("showPayment")
      this.dialogDetailVisible = true
      this.activeTab = "payment"
      this.recordData = row
    },
    dialogDetailOnClose() {
      this.dialogDetailVisible = false
      this.recordData = undefined
      //this.resetPage(this.getList)
    },
    fetchStationName(stationId){
      let stationName = stationId;
      this.stationList.forEach(function(station){
        if(station.id == stationId){
          stationName = station.name;
          return;
        }
      });
      return stationName;
    }
  }
}
</script>
<style lang="scss" scoped></style>
